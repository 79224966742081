.bottomMenu {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 70px;
  // background: rgba(255, 255, 255, 0.1); /* Glass effect */
  backdrop-filter: blur(10px);
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 -2px 12px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 1rem;
  z-index: 1000;
  font-family: 'Poppins', sans-serif; /* Premium font style */
}

.menuItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #fff;
  padding: 0.75rem;
  transition: color 0.3s ease, transform 0.3s ease;
  position: relative;
  cursor: pointer;

  &:hover {
    color: #28a745; /* Accent color for hover */
    transform: scale(1.1); /* Slight zoom effect on hover */
  }
}

.icon {
  font-size: 1.6rem; /* Adjust icon size for visibility */
  margin-bottom: 0.3rem;
}

.label {
  font-size: 0.8rem;
  font-weight: 500;
}

@media screen and (min-width: 768px) {
  .bottomMenu {
    height: 80px;
    padding: 0.5rem 2rem;
  }

  .menuItem {
    padding: 1rem;
  }

  .icon {
    font-size: 1.8rem;
  }

  .label {
    font-size: 0.9rem;
  }
}

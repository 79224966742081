.transaction-history {
  padding: 20px;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.1); /* Glass effect background */
  backdrop-filter: blur(10px); /* Glass effect blur */
  border: 1px solid rgba(255, 255, 255, 0.2); /* Light border for glass effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Drop shadow */
  color: #fff; /* Text color */
  max-width: 800px;
  margin: 0 auto;
  
  h2 {
    color: #ffffff; /* Heading color */
    margin-bottom: 20px;
    text-align: center;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    th, td {
      padding: 12px;
      text-align: left;
      border-bottom: 1px solid rgba(0, 139, 42, 0.452);
    }
    .filter-dropdown {
      margin-bottom: 20px;
    }
    
    #eventType {
      padding: 5px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
    
    th {
      background: rgba(0, 0, 0, 0.3);
      color: #ffffff;
    }

    tr:nth-child(even) {
      background: rgba(0, 0, 0, 0.2);
    }

    tr:hover {
      background: rgba(0, 0, 0, 0.4);
    }

    td {
      color: #ffffff; /* Data text color */
    }
  }

  p {
    text-align: center;
    color: #ffffff;
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .transaction-history {
    padding: 10px;
    border-radius: 10px;
  }

  table {
    font-size: 14px;
  }
}

/* Header.scss */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  backdrop-filter: blur(10px); /* Glass effect */
  // background-color: rgba(255, 255, 255, 0.2); /* Semi-transparent background */
  border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Light border for a premium look */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  z-index: 1000;
  
  .logo {
    display: flex;
    align-items: center;

    .menu-button {
      background: none;
      border: none;
      font-size: 24px;
      margin-right: 16px;
      color: #ffffff;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #40ff00; /* Accent color on hover */
      }
    }

    img {
      height: 40px; /* Adjust the size for responsiveness */
      width: auto;
      margin-left: 8px;
    }
  }

  .block_right {
    display: flex;
    align-items: center;
    
    a, .account-btn {
      color: #ffffff;
      text-decoration: none;
      font-size: 16px;
      margin-left: 24px;
      display: flex;
      align-items: center;
      transition: color 0.3s;
      
      &:hover {
        color: #ffb300; /* Accent color on hover */
      }
    }

    .account-btn {
      background: none;
      border: 2px solid rgba(255, 255, 255, 0.3);
      border-radius: 24px;
      padding: 6px;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: background-color 0.3s, border-color 0.3s;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
        border-color: rgba(255, 255, 255, 0.5);
      }

      svg {
        margin-right: 8px;
        fill: #ffffff;
      }

      span {
        font-weight: bold;
        color: #ffffff;
      }
    }
  }
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .header {
    padding: 12px;

    .logo {
      .menu-button {
        font-size: 20px;
      }

      img {
        height: 25px;
      }
    }

    .block_right {
      a, .account-btn {
        font-size: 14px;
        margin-left: 16px;
      }
    }
  }
}

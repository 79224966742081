.progress-bar {
    margin-top: 20px;
    height: 30px;
    background-color: #e0e0e0;
    border-radius: 15px;
    overflow: hidden;

    .progress {
      height: 100%;
      background: linear-gradient(90deg, #4caf50, #81c784);
      color: #fff;
      font-weight: bold;
      text-align: center;
      line-height: 30px;
      transition: width 0.5s ease;
    }
  }
/* SideMenu.scss */

.sideMenu {
  position: fixed;
  top: 0;
  left: -300px; /* Initially hidden off-screen */
  width: 300px;
  height: 100%;
  backdrop-filter: blur(10px); /* Glass effect */
  // background: rgba(255, 255, 255, 0.15); /* Semi-transparent white background */
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 20px 0 15px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: left 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
margin-top: 40px;
  &.open {
    left: 0; /* Slide in when open */
  }
}

.closeButton {
  background: none;
  color: #ffffff;
  border: none;
  font-size: 24px;
  margin-bottom: 20px;
  cursor: pointer;
  align-self: flex-end;
  transition: transform 0.3s, color 0.3s;

  &:hover {
    color: #11ff00; /* Accent color on hover */
    transform: rotate(90deg); /* Rotate effect on hover */
  }
}

.menuNav {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  a {
    color: #ffffff;
    text-decoration: none;
    font-size: 18px;
    margin-bottom: 15px;
    padding: 12px 16px;
    border-radius: 8px;
    transition: background-color 0.3s, color 0.3s, transform 0.3s;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      color: #48ff00; /* Accent color on hover */
      transform: scale(1.05); /* Slight zoom on hover */
    }
  }
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .sideMenu {
    width: 220px; /* Narrower for smaller screens */
    padding: 20px 15px;

    a {
      font-size: 16px;
      padding: 10px 14px;
    }
  }
}

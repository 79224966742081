/* Modal.scss */
// TransactionModal.scss

.transaction_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: radial-gradient(circle, rgba(0, 0, 0, 0.9) 20%, rgba(0, 0, 0, 0.7) 80%);
  z-index: 1000;
  backdrop-filter: blur(10px);
  animation: fadeIn 0.5s ease-in-out;
}

.crypto_icon_container {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  animation: float 3s infinite ease-in-out;
}

.crypto_icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: drop-shadow(0px 0px 15px rgba(0, 255, 255, 0.8));
}

.spinner_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.spinner {
  width: 18px;
  height: 18px;
  background: linear-gradient(45deg, #00ffcc, #0099ff);
  border-radius: 50%;
  animation: bounce 1.5s infinite ease-in-out;
}

.second {
  animation-delay: 0.2s;
}

.third {
  animation-delay: 0.4s;
}

.loading_text {
  margin-top: 20px;
  color: #00ffcc;
  font-size: 1.8rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 0px 0px 10px rgba(0, 255, 255, 0.7);
}

/* Animations */
@keyframes bounce {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

  
  /* Backdrop styling */
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    z-index: 999; /* Behind the modal */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
/* TokenDistributor.scss */
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');

/* containers for the entire component */
.containers{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f900;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(3, 249, 15, 0.631);
  // max-width: 400px;
  margin: 20px auto;
  font-family: 'Orbitron', sans-serif;
}

/* Title */
.containers h1 {
  font-family: "Orbitron", sans-serif;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #2dfd37;
}

/* Styling for each information section */
.containers p {
  font-size: 1rem;
  color: #f1e8e8;
  margin: 8px 0;
  font-family: 'Orbitron', sans-serif;

}

/* Styling for the strong text in the paragraphs */
.containers p strong {
  color: #02dd15;
  font-weight: bold;
  font-family: 'Orbitron', sans-serif;

}

/* Button Styling */
button {
  background-color: #18ee0c;
  color: white;
  font-size: 1.2rem;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
  font-family: 'Orbitron', sans-serif;

}

/* Button hover effect */
button:hover {
  background-color: #33b92981;
}

/* Responsive styling */
@media (max-width: 768px) {
  .containers {
    max-width: 100%;
    padding: 15px;
  }

  .containers h1 {
    font-size: 1.5rem;
  }

  .containers p {
    font-size: 0.9rem;
  }

  button {
    font-size: 1rem;
    padding: 8px 16px;
  }
}
.containers {
  text-align: center;
  padding: 20px;
}

.progress-bar-container {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 10px;
  margin: 20px 0;
  overflow: hidden;
  position: relative;
  height: 30px;
}

.progress-bar {
  height: 100%;
  background-color: #4caf50;
  text-align: center;
  line-height: 30px;
  color: white;
  border-radius: 10px;
  transition: width 0.3s ease;
}

button {
  margin: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

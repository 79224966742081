// 🎨 Global Styles
$primary-color: #73ff00;
$secondary-color: #0dff00;
$background-blur: rgba(255, 255, 255, 0.15);
$border-glow: rgba(255, 255, 255, 0.3);
$shadow-color: rgba(0, 0, 0, 0.3);

// 📌 Popup Overlay
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(8px);
  animation: fadeIn 0.5s ease-in-out;
}

// 📌 Popup Card
.popup-card {
  background: $background-blur;
  backdrop-filter: blur(12px);
  border-radius: 15px;
  box-shadow: 0px 4px 20px $shadow-color;
  padding: 20px;
  width: 90%;
  max-width: 400px;
  border: 1px solid $border-glow;
  text-align: center;
  color: #fff;
  position: relative;
  animation: slideIn 0.6s ease-in-out;
}

// 📌 Close Button
.close-btn {
  position: absolute;
  top: 12px;
  right: 12px;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: $primary-color;
    transform: scale(1.1);
  }
}

// 📌 USDT Logo Styling
.usdt-logo {
  width: 80px;
  height: 80px;
  margin: 10px auto;
  display: block;
  border-radius: 50%;
  border: 2px solid $primary-color;
}

// 📌 Headings & Text
h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  background: linear-gradient(45deg, $primary-color, $secondary-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

p {
  font-size: 14px;
  color: #e0e0e0;
  line-height: 1.5;
}

// 📌 Info Box
.info-box {
  background: rgba(255, 255, 255, 0.1);
  padding: 15px;
  border-radius: 10px;
  margin: 15px 0;
  box-shadow: inset 0px 0px 10px rgba(255, 255, 255, 0.1);
  text-align: left;
  overflow-y: auto;
  max-height: 150px;

  h3 {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    text-shadow: 0px 0px 5px $primary-color;
  }

  strong {
    color: $primary-color;
  }
}

// 📌 Custom Scrollbar
.info-box::-webkit-scrollbar {
  width: 5px;
}

.info-box::-webkit-scrollbar-track {
  background: transparent;
}

.info-box::-webkit-scrollbar-thumb {
  background: $primary-color;
  border-radius: 10px;
}

// 📌 Join Button
.join-btn {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background: linear-gradient(45deg, $primary-color, $secondary-color);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin-top: 10px;

  &:hover {
    transform: scale(1.05);
    background: linear-gradient(45deg, $secondary-color, $primary-color);
  }
}

// 📌 Animations
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

// 📌 Responsive Design
@media (max-width: 600px) {
  .popup-card {
    max-width: 90%;
  }
}

/* Glass Effect for Modal Backdrop */
.modal_backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  // background: rgba(0, 0, 0, 0.7); // Darker backdrop for better contrast
  z-index: 100;
  overflow-y: auto;
  transition: opacity 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px); // Blur effect for the entire background
}

/* Base Modal Style */
.base-modal {
  background: rgba(255, 255, 255, 0.15); // Enhanced glass effect
  border: 1px solid rgba(255, 255, 255, 0.2); // Subtle border for glass effect
  border-radius: 20px;
  padding: 2rem 3rem;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); // Stronger shadow for more depth
  backdrop-filter: blur(15px); // Enhanced glass blur effect
  max-width: 530px;
  width: 100%;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  opacity: 0; // Start hidden
  transform: scale(0.9); // Start scaled down

  @media (max-width: 576px) {
    max-width: 90%;
    padding: 1.5rem 2rem;
  }

  &.default {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    opacity: 1; // Fully visible when active
  }

  // Fade-in and zoom animation
  &.fade-in {
    animation: fadeInZoom 0.5s ease-out forwards;
  }

  @keyframes fadeInZoom {
    from {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0.9);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }
}

/* Transaction Modal Style */
.transaction-modal {
  background: rgba(255, 255, 255, 0.25); // Enhanced glass effect
  border-radius: 20px;
  color: #fff;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.3); // Subtle border for glass effect
  transition: all 200ms linear;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 530px;
  width: 100%;
  backdrop-filter: blur(12px); // Blurred glass effect

  @media (max-width: 576px) {
    max-width: 90%;
    padding: 1.5rem;
  }

  .transaction-modal-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    position: relative;

    .close-icon {
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      transition: color 0.3s ease;

      &:hover {
        color: #ff4757; // Hover effect color
      }

      svg {
        width: 32px;
        height: 32px;
        path {
          stroke: #fff;
        }
      }
    }
  }

  .transaction-modal-content {
    padding: 30px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .icon {
      width: 80px;
      height: 80px;
      margin-bottom: 20px;

      &.PENDING {
        animation: spin 1s ease-in-out infinite;
        border: 7px solid rgba(255, 255, 255, 0.4); // Softer color for spin animation
        border-top-color: #36d399; // Use a unique color for visual interest
        border-radius: 50%;

        @keyframes spin {
          100% {
            transform: rotate(360deg);
          }
        }

        svg {
          display: none; // Hide SVG when spinning
        }
      }

      svg {
        width: 80px;
        height: 80px;
      }
    }

    p {
      font-size: 1rem;
      line-height: 1.5; // Improved readability
      color: rgba(255, 255, 255, 0.8); // Slightly transparent text
      font-family: 'Poppins', sans-serif; // Use premium font
    }
  }
}

/* Responsive and Enhanced Styling for Premium Feel */
@media (max-width: 768px) {
  .base-modal,
  .transaction-modal {
    padding: 1rem 1.5rem;
  }

  .transaction-modal-content {
    p {
      font-size: 0.9rem; // Slightly smaller text for better fit
    }
  }
}

.how-container {
  display: grid;
  gap: 2rem;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

  .box {
    background: #000; // Black background for the boxes
    border: 3px solid #39ff14; // Neon green border
    border-radius: 12px;
    padding: 2rem;
    color: #fff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out;
    position: relative; // For the animation

    h5 {
      font-size: 1.8rem;
      color: #39ff14; // Neon green text color
      margin-bottom: 1.2rem;
      text-shadow: 0 0 10px #39ff14, 0 0 20px #39ff14, 0 0 30px #39ff14; // Neon effect on text
    }

    p, li {
      font-size: 1rem;
      line-height: 1.6;
      color: #eaeaea;
    }

    ul {
      list-style-type: disc;
      padding-left: 20px;
      margin-top: 0.5rem;
    }

    li {
      margin-bottom: 0.5rem;
    }

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.2);
      border-color: #00ff87; // Change the border color on hover to a different green shade
    }

    /* Neon Border Animation */
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 12px;
      // background: linear-gradient(45deg, #39ff14, #00ff87, #39ff14, #00ff87);
      background-size: 400%;
      animation: neon-border 1.5s infinite alternate;
      z-index: -1; // Ensure the border appears behind the content
    }
  }

  @media screen and (max-width: 768px) {
    padding: 1rem;
    grid-template-columns: 1fr;
  }

  /* Keyframe for neon border animation */
  @keyframes neon-border {
    0% {
      background-position: 0% 0%;
    }
    50% {
      background-position: 100% 100%;
    }
    100% {
      background-position: 0% 0%;
    }
  }
}

@import "../../index.scss";

// Glass Effect and Animations for Profile Image Editing

.edit-profile-images {
  display: flex;
  flex-wrap: wrap; // Allow items to wrap on smaller screens
  gap: 20px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1); // Glass effect background
  backdrop-filter: blur(15px); // Glass blur effect
  border-radius: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); // Subtle shadow for depth

  .image-upload {
    flex: 1;
    min-width: 250px; // Ensure items don't get too small
    display: flex;
    flex-direction: column; // Stack items vertically on smaller screens
    gap: 20px;
    padding: 20px;
    
    > section {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.2); // Light glass effect
      border: 2px dashed var(--bs-color-5);
      border-radius: 20px;
      transition: border-color 200ms linear, background-color 200ms linear;
      cursor: pointer;

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 10%;
        height: 100%;
        text-align: center;
        gap: 25px;

        img {
          width: 60px;
          height: 60px;
          object-fit: contain;
        }

        &:hover {
          border-color: var(--bs-primary);
          background-color: rgba(0, 0, 0, 0.3); // Darker glass effect on hover
        }
      }
    }

    .preview-image {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      
      img {
        max-width: 100%;
        width: 100%;
        aspect-ratio: 16/9;
        object-fit: contain;
        transition: opacity 300ms ease-in-out;
        opacity: 0;
        animation: fadeIn 0.5s ease-in forwards;
        
        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      }

      .drop-btn {
        cursor: pointer;
        p {
          color: var(--btn-primary);
          font-size: 16px;
          transition: color 200ms ease-in-out;
          
          &:hover {
            color: var(--btn-primary-hover);
          }
        }
      }
    }
  }
}

.edit-profile-wrapper {
  h5 {
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    color: var(--text-primary);
    margin-bottom: 20px;
  }

  input {
    background: rgba(255, 255, 255, 0.2); // Glass effect background
    width: 100%;
    padding: 12px 18px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    outline: none;
    color: #fff;
    margin: 26px 0;
    transition: background 200ms linear, border 200ms linear;

    &:focus {
      background: rgba(255, 255, 255, 0.3); // Slightly lighter on focus
      border: 1px solid var(--bs-primary);
    }
  }

  .edit-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 12px 0 22px;

    button {
      width: 100%;
      background: var(--btn-primary);
      color: #fff;
      border: none;
      border-radius: 8px;
      padding: 12px;
      font-size: 16px;
      transition: background 200ms ease, transform 200ms ease;

      &:hover {
        background: var(--btn-primary-hover);
        transform: scale(1.05);
      }

      &:active {
        transform: scale(1);
      }
    }
  }
}

// Responsive Styles
@media (max-width: 768px) {
  .edit-profile-images {
    flex-direction: column; // Stack items vertically on smaller screens
  }

  .image-upload {
    width: 100%;
    flex-direction: column; // Stack items vertically
    gap: 20px;
  }

  .image-upload > section {
    width: 100%; // Ensure section takes full width on small screens
    height: auto; // Allow height to adjust based on content
  }

  .edit-profile-wrapper h5 {
    font-size: 20px; // Adjust font size for smaller screens
  }

  .edit-profile-wrapper input {
    font-size: 14px; // Adjust font size for smaller screens
  }

  .edit-profile-wrapper .edit-btn button {
    font-size: 14px; // Adjust font size for smaller screens
    padding: 10px; // Adjust padding for smaller screens
  }
}

@media (max-width: 576px) {
  .edit-profile-images {
    padding: 10px; // Reduce padding on very small screens
  }

  .edit-profile-wrapper {
    h5 {
      font-size: 18px; // Further adjust font size for very small screens
    }

    input {
      padding: 10px 14px; // Adjust padding for very small screens
      font-size: 12px; // Adjust font size for very small screens
    }

    .edit-btn button {
      font-size: 12px; // Adjust font size for very small screens
      padding: 8px; // Adjust padding for very small screens
    }
  }
}

/* Button styles */
.edit-btn {
  display: flex;
  justify-content: center;
  width: 100%;
}

.edit-btn Button {
  background-color: #007bff; /* Blue background */
  color: #fff; /* White text */
  border: none;
  border-radius: 8px; /* Rounded corners */
  padding: 10px 20px; /* Padding inside the button */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
}

.edit-btn Button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.edit-btn Button:active {
  transform: scale(0.98); /* Slight scaling effect on click */
}

.roadmap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 2rem;
  background: #0a0a0a;
  color: #ffffff;

  .timeline {
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    border-left: 3px solid #39ff14;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 3px;
      background: linear-gradient(to bottom, #39ff14, #00ff00);
    }

    .milestone {
      position: relative;
      margin: 2rem 0;
      padding: 1rem;
      background: rgba(255, 255, 255, 0.1);
      border: 2px solid #39ff14;
      border-radius: 10px;
      box-shadow: 0px 8px 15px rgba(0, 255, 104, 0.2);
      transition: transform 0.3s ease, box-shadow 0.3s ease;

      &:hover {
        transform: translateY(-10px);
        box-shadow: 0px 12px 20px rgba(0, 255, 104, 0.4);
      }

      .date {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -10%;
        color: #39ff14;
        font-size: 1.2rem;
        font-weight: bold;
        white-space: nowrap;
      }

      .details {
        margin-left: 3rem;

        h4 {
          margin-bottom: 0.5rem;
          font-size: 1.5rem;
          color: #39ff14;
        }

        p {
          font-size: 1rem;
          line-height: 1.5;
          color: #d4d4d4;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .timeline {
      .milestone {
        .date {
          left: -20%;
          font-size: 1rem;
        }

        .details {
          margin-left: 2rem;
          h4 {
            font-size: 1.2rem;
          }

          p {
            font-size: 0.9rem;
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    .timeline {
      padding: 0 1rem;

      .milestone {
        .date {
          left: -30%;
        }

        .details {
          margin-left: 1.5rem;
        }
      }
    }
  }
}

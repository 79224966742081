.aboutus {
  padding: 3rem 1rem;
  color: #fff;

  .wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  }

  .aboutus_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .block_text {
    text-align: center;
    margin-bottom: 2rem;

    h3 {
      font-size: 2rem;
      color: #39ff14;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1.1rem;
      line-height: 1.7;
      color: #eaeaea;
      margin-bottom: 1rem;
    }
  }

  .working_title {
    text-align: center;
    margin: 3rem 0 2rem;
    font-size: 1.8rem;
    color: #39ff14;
  }

  .about_card {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
  }

  .choose__box {
    background: #000; // Black background for the boxes
    border: 3px solid #39ff14; // Neon green border
    border-top-left-radius: 30%;
    padding: 1.5rem;
    text-align: center;
    color: #fff;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    position: relative;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.2);
      border-color: #00ff87; // Slightly different green on hover
    }

    .choose__box_icon {
      display: flex;
      flex-direction: column;
      align-items: center;

      .icon {
        font-size: 1.5rem;
        color: #39ff14;
        margin-bottom: 1rem;
        font-weight: bold;
        text-shadow: 0 0 10px #39ff14, 0 0 20px #39ff14, 0 0 30px #39ff14; // Neon text glow effect
      }

      .content {
        font-size: 1rem;
        line-height: 1.6;
        color: #eaeaea;
      }
    }

    /* Neon Border Animation */
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      // background: linear-gradient(45deg, #39ff14, #00ff87, #39ff14, #00ff87);
      background-size: 400%;
      animation: neon-border 1.5s infinite alternate;
      z-index: -1; // Ensure the border appears behind the content
    }
  }

  @media screen and (max-width: 768px) {
    .about_card {
      grid-template-columns: 1fr;
    }

    .block_text h3 {
      font-size: 1.6rem;
    }

    .working_title {
      font-size: 1.5rem;
    }
  }
}

.recentActivity {
  padding: 20px;
  background-color: #000; // Black background for the recent activity box
  border: 3px solid #39ff14; // Neon green border
  border-radius: 8px;
  color: #fff;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #f5c518; // Neon yellow-green for header
  }

  p {
    margin-bottom: 20px;
    font-size: 24px;
    color: #eaeaea;
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      margin-bottom: 10px;
      font-size: 18px;
      color: #eaeaea;
    }
  }

  /* Neon effect for recent activity box on hover */
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.2);
    border-color: #00ff87; // Green hover effect
  }

  /* Neon Border Animation */
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: linear-gradient(45deg, #39ff14, #00ff87, #39ff14, #00ff87);
    background-size: 400%;
    animation: neon-border 1.5s infinite alternate;
    z-index: -1; // Ensure the border appears behind the content
  }
}

/* Keyframe for neon border animation */
@keyframes neon-border {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

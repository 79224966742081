// Variables for colors and effects
$primary-color: #00e804;
$secondary-color: #ffffff;
$background-glass: rgba(255, 255, 255, 0.15);
$border-glass: rgba(255, 255, 255, 0.25);
$button-hover: rgba(255, 255, 255, 0.2);

.card22 {
  display: column;
  // flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  background: $background-glass;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(10px);
  border: 1px solid $border-glass;
  width: 70%;
  max-width: 50%;
  color: $secondary-color;
  font-family: "Poppins", sans-serif;
}

.card2 {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  padding: 1.2rem;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-weight: bold;
}

h5 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

p {
  font-size: 1.5rem;
  font-weight: bold;
  color: $secondary-color;
}

// Buttons
.withdrawButton,
.connectButton {
  background: $primary-color;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);

  &:hover {
    background: darken($primary-color, 10%);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  }
}

// Modal Styling
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  background: rgba(255, 255, 255, 0.1);
  padding: 2rem;
  border-radius: 12px;
  text-align: center;
  backdrop-filter: blur(12px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: 80%;

}

input {
  width: 100%;
  padding: 0.75rem;
  border-radius: 8px;
  border: none;
  margin-bottom: 1rem;
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  text-align: center;
  font-size: 1.8rem;
  outline: none;

  &::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
}

button {
  background: $primary-color;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background: darken($primary-color, 10%);
  }

  &:disabled {
    background: gray;
    cursor: not-allowed;
  }
}

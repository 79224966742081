.privacy-container {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: "Poppins", sans-serif;
    color: #333;
  
    h2 {
      text-align: center;
      color: #09ab00;
      margin-bottom: 20px;
      font-size: 28px;
    }
  
    section {
      margin-bottom: 20px;
  
      h3 {
        color: #21b300;
        border-left: 5px solid #21b300;
        padding-left: 10px;
        font-size: 20px;
      }
  
      p {
        font-size: 16px;
        line-height: 1.6;
        color: #141212;
      }
  
      ul {
        margin-top: 10px;
        padding-left: 20px;
  
        li {
          margin-bottom: 5px;
          font-size: 14px;
        }
      }
    }
  
    footer {
      text-align: center;
      margin-top: 20px;
      font-size: 14px;
      font-weight: bold;
      color: #d9534f;
    }
  
    // Responsive Styles
    @media screen and (max-width: 768px) {
      max-width: 90%;
      padding: 15px;
  
      h2 {
        font-size: 24px;
      }
  
      section {
        h3 {
          font-size: 18px;
        }
  
        p {
          font-size: 14px;
        }
  
        ul {
          padding-left: 15px;
  
          li {
            font-size: 13px;
          }
        }
      }
  
      footer {
        font-size: 12px;
      }
    }
  
    @media screen and (max-width: 480px) {
      max-width: 95%;
      padding: 10px;
  
      h2 {
        font-size: 22px;
      }
  
      section {
        h3 {
          font-size: 16px;
        }
  
        p {
          font-size: 13px;
        }
  
        ul {
          padding-left: 10px;
  
          li {
            font-size: 12px;
          }
        }
      }
  
      footer {
        font-size: 10px;
      }
    }
  }
  
$primary-neon: #00ffcc; // Neon cyan
$secondary-neon: #80ff00; // Neon pink
$background-glass: rgba(0, 0, 0, 0.989); // Semi-transparent for glass effect
$button-hover-neon: #48ff00; // Neon yellow
$input-border-neon: rgba(35, 238, 9, 0.3);
$text-shadow-neon: 0 0 8px $primary-neon, 0 0 12px $secondary-neon;
$glass-border-neon: rgba(7, 235, 41, 0.5);

.token-trading-app {
  // margin-top: 10rem;
  font-family: 'Roboto', sans-serif;
  // background: radial-gradient(circle, #0f0c29, #302b63, #24243e);
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  h1 {
    color: $primary-neon;
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-shadow: $text-shadow-neon;
  }

  .account-info {
    font-size: 1.1rem;
    color: $secondary-neon;
    margin-bottom: 40px;
    text-shadow: $text-shadow-neon;
  }

  .trade-section {
    width: 90%;
    max-width: 400px;
    margin-bottom: 30px;
    text-align: center;
    backdrop-filter: blur(15px);
    background: $background-glass;
    border: 1px solid $glass-border-neon;
    border-radius: 16px;
    padding: 20px;
    box-shadow: 0 8px 16px rgba(2, 230, 44, 0.6);

    h2 {
      color: $secondary-neon;
      font-size: 1.8rem;
      margin-bottom: 15px;
      text-shadow: $text-shadow-neon;
    }

    .input-field {
      width: 100%;
      padding: 12px;
      font-size: 1rem;
      border: 1px solid $input-border-neon;
      border-radius: 8px;
      margin-bottom: 15px;
      box-sizing: border-box;
      background: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(5px);
      color: white;
      outline: none;

      &:focus {
        border-color: $primary-neon;
        box-shadow: 0 0 10px $primary-neon;
      }
    }

    .action-button {
      background: linear-gradient(45deg, $primary-neon, $secondary-neon);
      color: white;
      padding: 12px 20px;
      font-size: 1.1rem;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      width: 100%;
      transition: all 0.3s ease;

      &:hover {
        background: $button-hover-neon;
        box-shadow: 0 0 15px $button-hover-neon, 0 0 30px $primary-neon;
        transform: scale(1.05);
      }

      &:disabled {
        background-color: #aaa;
        cursor: not-allowed;
      }
    }
  }

  .token-price {
    margin-top: 40px;
    font-size: 1.2rem;
    color: white;
    text-shadow: $text-shadow-neon;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 2rem;
    }

    .trade-section {
      padding: 15px;
    }

    .action-button {
      font-size: 1rem;
    }

    .token-price {
      font-size: 1rem;
    }
  }

  @media (max-width: 480px) {
    h1 {
      font-size: 1.8rem;
    }

    .action-button {
      padding: 10px 15px;
    }
  }
}

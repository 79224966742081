.faq {
  padding: 2rem;
  //background: linear-gr+adient(135deg, #101820, #2a2e35);
  color: #fff;

  &-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;

    section.heading {
      text-align: center;
      margin-bottom: 2rem;

      h2 {
        font-size: 2.5rem;
        font-weight: bold;
        color: #39ff14;
        text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.8);
      }
    }
  }

  &-section-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &-card-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem;
  }

  .faq-card {
    background: #1a2025;
    border-radius: 10px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.5);
    padding: 1.5rem;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0px 12px 20px rgba(0, 255, 4, 0.4);
    }

    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      h5.question {
        margin: 0;
        font-size: 1.2rem;
        font-weight: 600;
        color: #39ff14;
      }

      img {
        width: 20px;
        height: 20px;
      }
    }

    .faq-answer {
      margin-top: 1rem;
      font-size: 1rem;
      line-height: 1.5;
      color: #d4d4d4;
      border-left: 3px solid #39ff14;
      padding-left: 1rem;
      animation: fadeIn 0.3s ease-in-out;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.profileBanner {
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px 40px;
  background: rgba(0, 0, 0, 0);
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(64, 255, 0, 0.5);
  backdrop-filter: blur(15px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  transition: all 0.4s ease-in-out;

  @media (max-width: 1024px) {
    max-width: 95%;
    padding: 20px 30px;
  }

  @media (max-width: 768px) {
    // flex-direction: column;
    text-align: center;
    padding: 20px;
  }
}

/* Glow Effect */
.profileBanner::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(0, 255, 55, 0.2), transparent);
  z-index: -1;
  animation: glowing 8s linear infinite;
}

@keyframes glowing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.profileLeft {
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.profileImage img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 4px solid #00ff26;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  }
}

.profileInfo {
  margin-left: 20px;

  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 15px;
  }
}

.profileInfo h4 {
  font-size: 24px;
  font-weight: bold;
  background: linear-gradient(45deg, #00ff2f, #107e01);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 768px) {
    font-size: 20px;
  }
}
.editProfile {
  display: flex;
  align-items: center;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.2);
  padding: 7px 12px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }

  .icon {
    font-size: 18px;
    margin-right: 5px;
  }
}
.profileInfo p {
  font-size: 16px;
  color: #ddd;

  @media (max-width: 768px) {
    font-size: 14px;
  }
}

.profileRight {
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 768px) {
    margin-top: 15px;
    flex-direction: column;
  }
}

.copyButton, .copiedButton {
  background: linear-gradient(45deg, #00ff2f, #107e01);
  border: none;
  padding: 12px 20px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  box-shadow: 0 4px 10px rgba(0, 255, 17, 0.5);

  &:hover {
    background: linear-gradient(45deg, #00ff2f, #107e01);
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    padding: 10px 16px;
    font-size: 14px;
  }
}

.copyIcon {
  font-size: 18px;
}

.rewardsButton {
  background: linear-gradient(45deg, #00ff2f, #107e01);
  border: none;
  padding: 12px 22px;
  border-radius: 12px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  box-shadow: 0 4px 12px rgba(30, 200, 0, 0.5);

  &:hover {
    background: linear-gradient(45deg, #00ff2f, #107e01);
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    padding: 10px 18px;
    font-size: 14px;
  }
}

.giftIcon {
  font-size: 20px;

  @media (max-width: 768px) {
    font-size: 18px;
  }
}


.card-container2 {
  display: flex;
  flex-direction: column;
  // gap: 1rem;
  justify-content: space-between;
  border-color: #fff;
   
}

.card-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
border: 2px;
border-color: #fff;
}

.card2-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  border: 2px;
border-color: #fff;
}
.referral-earnings-section {
  text-align: center;
  margin: 1rem auto;
  padding: 1rem;
}

.referral-earnings-card {
  background: rgba(255, 255, 255, 0); /* Glass effect */
  backdrop-filter: blur(10px); /* Glass effect */
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0);
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;

  // h5 {
  //   margin-bottom: 0.5rem;
  //   font-size: 1.2rem;
  //   font-weight: bold;
  // }

  p {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  button {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 8px;
    background: #00ff26; /* Green button */
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.3s ease;

    &:hover {
      background: #217088; /* Darker green on hover */
      transform: scale(1.05);
    }
  }
}

.card2 {
  flex: 1;
  min-width: 150px;
  padding: 1.5rem;
  border-radius: 12px;
  background: #000; /* Black background */
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  // Adding content with elevated effect
  box-shadow: 0 4px 10px rgba(0, 255, 42, 0.5); /* Subtle green glow */

  // Rotating neon border using pseudo-element
  &::before {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
    border-radius: 12px;
    // background: linear-gradient(90deg, #00ff00, transparent, #00ff00, transparent);
    background-size: 300% 300%; /* Enlarged gradient */
    animation: neonRotate 3s linear infinite; /* Smooth rotation */
    z-index: -1; /* Behind the card */
  }
  .icon {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #ffffff;
  }
  // Hover interaction
  &:hover {
    box-shadow: 0 6px 15px rgba(13, 255, 0, 0.7); /* Enhanced glow on hover */
    transform: scale(1.05); /* Scale effect */
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
}

.card22{

  display: table-column-group;
}

// Rotating border animation
@keyframes neonRotate {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}


// .card {
//   background: linear-gradient(135deg, #2c3e50, #34495e); /* Green glass effect */
//   border: 2px;
// border-color: #ffffff;
// }

// .card2 {
//   background: linear-gradient(135deg, #2c3e50, #34495e); /* Darker green glass effect */
//   border: 2px;
// border-color: #fff;
// }

.card2.image1 {
  background: linear-gradient(45deg, #00ff2f, #107e01);
  border: 2px;
border-color: #fff;
}

.card2.image2 {
  background: linear-gradient(45deg, #00ff2f, #107e01);
  border: 2px;
border-color: #fff;
}

@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');

.card h5,
.card2 h5 {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  color: #00ff26; /* Neon green */
  // border: 3px solid #39ff14;
  // text-shadow: 0 0 5px #39ff14, 0 0 10px #39ff14, 0 0 20px #39ff14, 0 0 30px #39ff14;
  font-family: 'Orbitron', sans-serif; /* Stylish font */
  letter-spacing: 1px; /* Add some spacing for a premium look */
  text-transform: uppercase; /* Make the text all caps for a bold effect */
}


.card p, .card2 p {
  font-size: 1.5rem;
  font-weight: 600;
}
.highlight {
  margin-top: 0.5rem;
  font-size: 1rem;
  color: #fff;
  background-color: rgba(202, 212, 200, 0.125); /* Slightly darker background for highlights */
  padding: 0.5rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  position: relative;
  width: 100%;
  
  .income-increase {
    color: #28a745; /* Green for positive income */
  }

  .arrow-up {
    color: #28a745; /* Arrow in green color */
    margin-left: 5px; /* Add some space between the amount and the arrow */
    font-size: 15px;
    font-weight: bold;
  }
}


.referral-section {
  text-align: center;
  margin-bottom: 1rem;
}

button {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  background: #00ff26; /* Green button */
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;

  &:hover {
    background: #00ff26; /* Darker green on hover */
    transform: scale(1.05);
  }
}

.progress-card {
  background: linear-gradient(45deg, #00ff2f, #107e01);
  border: 2px;
border-color: #fff;
  
  h3 {
    text-align: center;
    color: #00ff26;
  }

  .progress-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    
    .label {
      font-size: 14px;
      color: #f0efef;
    }
    
    .progress-bar {
      width: 70%;
      height: 10px;
      background: #eee;
      border-radius: 5px;
      position: relative;
      overflow: hidden;
      
      .filled-bar {
        height: 100%;
        background: #00ff26;
        border-radius: 5px;
      }
    }
  }

  .next-level-info {
    text-align: center;
    font-size: 14px;
    color: #00ff26;
  }
}

.profile-container {
  background: #101010;
  color: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  font-family: 'Poppins', sans-serif;

  .profile-header {
    display: flex;
    align-items: center;
    gap: 15px;

    .profile-image {
      border-radius: 50%;
      overflow: hidden;
      width: 60px;
      height: 60px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .profile-info {
      flex-grow: 1;
      h4 {
        font-size: 1.2rem;
        margin: 0;
      }
      p {
        font-size: 0.9rem;
        color: #ccc;
      }
    }

    .edit-profile {
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;
      svg {
        width: 20px;
        height: 20px;
      }
      h5 {
        font-size: 0.85rem;
        margin: 0;
      }
    }
  }

  .referrer-details {
    margin-top: 15px;

    .referrer-info {
      p {
        font-size: 0.85rem;
        margin: 5px 0;
      }
    }

    .open-referrals-btn {
      margin-top: 10px;
      padding: 8px 12px;
      background: linear-gradient(45deg, #00ff2f, #107e01);
      color: #fff;
      border: none;
      border-radius: 8px;
      font-size: 0.85rem;
      cursor: pointer;
      transition: background 0.3s;

      &:hover {
        background: linear-gradient(45deg, #00ff2f, #107e01);
      }
    }
  }
}
.profile-container {
  width: 100%;
  max-width: 48rem;
  padding: 1.5rem;
  border-radius: 0.75rem;
  background-color: #1a1b1f;
  color: white;

  .profile-header {
    display: flex;
    align-items: flex-start;
    gap: 1.5rem;

    .profile-image {
      position: relative;

      .avatar-wrapper {
        width: 6rem;
        height: 6rem;
        border-radius: 9999px;
        background: linear-gradient(45deg, #00ff2f, #107e01);
        padding: 2px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 9999px;
          object-fit: cover;
          background-color: #1f2937;
        }
      }

      .social-counter {
        position: absolute;
        bottom: -0.5rem;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;

        .counter-pill {
          padding: 0.25rem 1rem;
          border-radius: 9999px;
          background-color: #2c2d31;
          font-size: 0.875rem;
          display: flex;
          align-items: center;
          gap: 0.5rem;

          .heart {
            color: #03dc4f;
          }
        }
      }
    }

    .profile-info {
      flex: 1;

      .id-section {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-bottom: 0.5rem;

        h1 {
          font-size: 1.5rem;
          font-weight: 700;
        }

        .copy-button {
          color: #9ca3af;
          cursor: pointer;
          transition: color 0.2s;

          &:hover {
            color: white;
          }
        }
      }

      .address {
        color: #9ca3af;
        font-size: 0.875rem;
        margin-bottom: 1rem;
      }

      .invited-info {
        color: #9ca3af;
        font-size: 0.875rem;
      }
    }
  }

  .personal-link {
    margin-top: 1.5rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #2c2d31;

    .link-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.25rem;

      .label {
        color: #9ca3af;
      }

      .copy-button {
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
        border-radius: 0.375rem;
        background-color: #44dbf2;
        color: white;
        cursor: pointer;
        transition: background-color 0.2s;

        &:hover {
          background-color: #3451e2;
        }
      }
    }

    .link-text {
      color: #00ff26;
    }
  }

  .token-section {
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background: linear-gradient(
      to right,
      rgba(147, 51, 234, 0.1),
      rgba(59, 130, 246, 0.1),
      rgba(34, 197, 94, 0.1)
    );
    display: flex;
    align-items: center;
    justify-content: space-between;

    .token-info {
      display: flex;
      align-items: center;
      gap: 0.75rem;

      .token-icon {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 9999px;
        background: linear-gradient(45deg, #00ff2f, #107e01);
      }

      .token-name {
        font-weight: 600;
      }
    }

    .login-button {
      padding: 0.5rem 1rem;
      border-radius: 0.375rem;
      border: 1px solid rgba(34, 197, 53, 0.5);
      color: #00ff26;
      background-color: transparent;
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background-color: rgba(34, 197, 94, 0.1);
      }
    }
  }
}

// Animation for copy feedback
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

.copy-feedback {
  animation: fadeIn 0.2s ease-in, fadeOut 0.2s ease-out 1.8s;
}

.profile-container {
  background: #111;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  width: 100%;
  max-width: 400px;
}

.profile-header {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.profile-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #00ff26;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.profile-info {
  flex: 1;
  margin-left: 15px;
  h4 {
    font-size: 18px;
    margin: 0;
  }
}

.edit-profile {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #aaa;
  svg {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }
  h5 {
    margin: 0;
    font-size: 14px;
  }
  &:hover {
    color: #fff;
  }
}

.referrer-details {
  width: 100%;
  margin-top: 20px;
  background: #222;
  padding: 10px;
  border-radius: 8px;
  .referer-info {
    p {
      margin: 5px 0;
      font-size: 14px;
      color: #ccc;
    }
  }
}

.open-referrals-btn {
  background: linear-gradient(45deg, #00ffcc, #0099ff);
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  &:hover {
    background: linear-gradient(45deg, #00ff2f, #107e01);
  }
}

.photo-update {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
  .upload-btn {
    background: #444;
    color: #fff;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background: #666;
    }
  }
}

.withdraw-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.modal-content input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal-actions button {
  margin: 5px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-actions button:first-child {
  background: #4caf50;
  color: white;
}

.modal-actions button:last-child {
  background: #f44336;
 
  color: white;
}

.profile-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  backdrop-filter: blur(10px);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  color: #fff;
  
  h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
  }
}

.progress-bar-container {
  width: 100%;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  padding: 5px;
  margin-top: 20px;
  position: relative;
  overflow: hidden;
}

.progress-bar {
  height: 20px;
  border-radius: 10px;
  transition: width 0.5s ease-in-out;
  
  &.in-progress {
    background: linear-gradient(90deg, #007bff, #00c3ff);
  }

  &.completed {
    background: linear-gradient(90deg, #4caf50, #00e676);
  }
}

.progress-text {
  text-align: center;
  font-size: 14px;
  margin-top: 5px;
  font-weight: bold;
}

.stake-info {
  margin-top: 15px;
  font-size: 16px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  p {
    margin: 5px 0;
    font-size: 14px;
    color: #ddd;

    strong {
      color: #fff;
    }
  }
}

// AdvertisingPage.scss
.advertising-page {
    display: flex;
    justify-content: center;
    align-items: center;
    // height: 100vh;
    // background: linear-gradient(to right, #1a2a6c, #b21f1f, #fdbb2d);
    color: #fff;
    font-family: 'Arial', sans-serif;
  
    .content-container {
      text-align: center;
      padding: 20px;
      border-radius: 10px;
      background: rgba(1, 36, 5, 0.211);
      // box-shadow: 0 8px 15px rgba(3, 216, 39, 0.406);
  
      .headline {
        font-family: 'Orbitron', sans-serif; /* Stylish font */

        font-size: 2.5rem;
        font-weight: bold;
        margin-bottom: 10px;
        color: #2dfd37;
      }
  
      .subheadline {
        font-size: 1.2rem;
        margin-bottom: 20px;
        font-family: 'Orbitron', sans-serif; /* Stylish font */

        span {
          color: #49fd2d;
          font-weight: bold;
        }
      }
  
      .timer {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
  
        .timer-box {
          margin: 0 10px;
          text-align: center;
  
          span {
            display: block;
            font-size: 2rem;
            font-weight: bold;
            font-family: 'Orbitron', sans-serif; /* Stylish font */

          }
  
          p {
            margin: 0;
            font-size: 1rem;
          }
        }
      }
  
      .cta-button {
        padding: 15px 30px;
        font-size: 1rem;
        font-weight: bold;
        font-family: 'Orbitron', sans-serif; /* Stylish font */

        color: #000000;
        background: #2dfd37;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: transform 0.3s, box-shadow 0.3s;
  
        &:hover {
          transform: scale(1.05);
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
  @import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');

$neon-green: #39ff14;
$black-green: #2c3e50;
$dark-bg: #0a0a0a;
$light-bg: #1c1c1c;
$text-color: #ecf0f1;

.contactus {
  padding: 40px 0;
  color: $text-color;

  .footer {
    &__top {
      padding: 40px 0;
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .content_left {
    flex: 1;

    h3 {
      font-size: 2rem;
      margin-bottom: 1rem;
      color: $neon-green;
      animation: neonText 2s infinite alternate;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        
        span {
          margin-left: 10px;
          font-size: 1.1rem;
          color: $text-color;
        }

        a {
          text-decoration: none;
          color: $neon-green;
          font-size: 1rem;
          transition: color 0.3s;

          &:hover {
            color: #d4af37;
            text-shadow: 0 0 10px $neon-green, 0 0 20px $neon-green;
          }
        }
      }
    }
  }

  .lists_social {
    display: flex;
    margin-top: 20px;

    li {
      margin-right: 20px;

      a {
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: $black-green;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background 0.3s, transform 0.3s;
        box-shadow: 0 0 5px $neon-green;

        &:hover {
          background: $neon-green;
          transform: scale(1.1);
        }

        svg {
          width: 24px;
          height: 24px;
          fill: $text-color;
          transition: fill 0.3s, transform 0.3s;

          &:hover {
            fill: $black-green;
            transform: scale(1.2);
          }
        }
      }
    }
  }

  .lists_social + .lists_social {
    margin-top: 20px;
  }

  @media (max-width: 768px) {
    .content_left {
      text-align: center;
      margin-bottom: 30px;
    }

    .lists_social {
      justify-content: center;
    }
  }
}

@keyframes neonText {
  from {
    text-shadow: 0 0 5px $neon-green, 0 0 10px $neon-green, 0 0 20px $neon-green;
  }
  to {
    text-shadow: 0 0 10px #292929, 0 0 15px #292929, 0 0 30px #292929;
  }
}

.features {
  padding: 60px 0;

  .block_header {
    text-align: center;
    margin-bottom: 40px;
    h3 {
      font-size: 28px;
      color: #ffffff;
    }
  }

  .features_card_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
    overflow: hidden;
    animation: slideIn 2s ease-in-out forwards;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .features_card {
    background-color: #0a0a0a; /* Black background */
    border-bottom-right-radius: 12%;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    width: 22%;
    transition: transform 0.3s ease, border 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid transparent; /* Set initial border color to transparent */
    position: relative;
    color: #39ff14; /* Neon green text color */
    text-shadow: 0 0 10px #39ff14, 0 0 20px #39ff14, 0 0 30px #39ff14; /* Neon green text effect */

    @media (max-width: 1024px) {
      width: 45%;
    }

    @media (max-width: 768px) {
      width: 80%;
      margin-bottom: 20px;
    }

    /* Continuously glowing border */
    border-color: #39ff14; /* Neon green border color */
    animation: borderGlow 1.5s ease-in-out infinite alternate; /* Neon border glow animation */

    &:hover {
      transform: translateY(-10px);
    }

    .icon {
      font-size: 40px;
      color: #38b2ac;
      margin-bottom: 16px;
    }

    a {
      font-size: 20px;
      font-weight: bold;
      color: #39ff14;
      text-decoration: none;
      margin-bottom: 10px;
      text-shadow: 0 0 5px #39ff14, 0 0 10px #39ff14; /* Optional text effect for links */
    }

    p {
      font-size: 16px;
      color: #ffffff;
    }
  }

  @keyframes slideIn {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }

  /* Neon border glow animation */
  @keyframes borderGlow {
    0% {
      border-color: transparent;
    }
    50% {
      border-color: #39ff14; /* Neon green */
    }
    100% {
      border-color: transparent;
    }
  }
}

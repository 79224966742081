.hero {
  position: relative;
  padding: 60px 0;
  color: white;
  text-align: center;
  overflow: hidden;
  background-color: #0a0a0a; /* Black background */
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom : 2px solid transparent; /* Transparent border for now */
  animation: borderGlow 1.5s ease-in-out infinite alternate; /* Neon green border glow animation */

  .mx {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .banner-top__content {
    position: relative;
    z-index: 2;
  }

  .title {
    font-size: 3.5rem;
    font-weight: 700;
    color: #39ff14; /* Neon green text */
    margin-bottom: 20px;
    background: linear-gradient(90deg, #39ff14 0%, #00ff8c 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 0 10px #39ff14, 0 0 20px #39ff14, 0 0 30px #39ff14; /* Neon glow effect */
  }

  .desc {
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 30px;
    color: #e1e1e1;
  }

  .controls {
    button {
      background: linear-gradient(90deg, #39ff14 0%, #00ff8c 100%); /* Neon gradient background */
      color: white;
      font-weight: 600;
      font-size: 2rem;
      padding: 12px 25px;
      border-radius: 50px;
      border: none;
      margin-top: 50px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      text-shadow: 0 0 10px #39ff14, 0 0 20px #39ff14;

      &:hover {
        background: linear-gradient(90deg, #00ff8c 0%, #39ff14 100%);
        transform: scale(1.05);
        text-shadow: 0 0 15px #39ff14, 0 0 25px #39ff14; /* Neon effect on hover */
      }
    }
  }

  /* Countdown Styling */
  .countdown {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 40px;

    div {
      text-align: center;

      .countdown__value {
        font-size: 3rem;
        font-weight: 700;
        background: linear-gradient(135deg, #39ff14 0%, #00ff8c 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        padding: 10px;
        border-radius: 15px;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
        animation: countdownGlow 1.5s ease-in-out infinite alternate;
      }

      &::before {
        content: attr(data-value);
        font-size: 1rem;
        color: #e1e1e1;
        display: block;
        margin-top: 10px;
      }
    }
  }

  /* Network Animation */
  .networkBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1;

    .node {
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: #00bfff;
      border-radius: 50%;
      opacity: 0.8;
      animation: nodeMovement 10s infinite alternate;
    }

    .line {
      position: absolute;
      width: 2px;
      background-color: rgba(255, 255, 255, 0.5);
      animation: lineAnimation 10s infinite;
    }

    @keyframes nodeMovement {
      0% {
        transform: translate(-50%, -50%);
      }
      50% {
        transform: translate(50%, 50%);
      }
      100% {
        transform: translate(-50%, 50%);
      }
    }

    @keyframes lineAnimation {
      0% {
        transform: scaleX(0);
      }
      50% {
        transform: scaleX(1);
      }
      100% {
        transform: scaleX(0);
      }
    }
  }
}

/* Border glow animation for hero section */
@keyframes borderGlow {
  0% {
    border-color: transparent;
  }
  50% {
    border-color: #39ff14; /* Neon green */
  }
  100% {
    border-color: transparent;
  }
}

/* Countdown glow animation */
@keyframes countdownGlow {
  0% {
    text-shadow: 0 0 5px #39ff14, 0 0 10px #39ff14;
  }
  50% {
    text-shadow: 0 0 20px #39ff14, 0 0 30px #39ff14;
  }
  100% {
    text-shadow: 0 0 5px #39ff14, 0 0 10px #39ff14;
  }
}

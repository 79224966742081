// Glass effect styles for footer
.footer {
    // background: rgba(255, 255, 255, 0.1); // Semi-transparent background for glass effect
    color: #fff; // Text color
    backdrop-filter: blur(10px); // Glass effect
    padding: 1.5rem 2rem; // Padding for spacing
    border-radius: 10px; // Rounded corners
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); // Shadow for depth
    transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  
    // Hover effect
    &:hover {
      background: rgba(255, 255, 255, 0.2); // Slightly more opaque on hover
      transform: translateY(-2px);
      box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
    }
  
    // Footer text styling
    p {
      margin: 0;
      font-size: 1.4rem; // Font size
      line-height: 2rem; // Line height
      animation: fadeIn 0.6s ease-in-out; // Fade-in animation
    }
  
    // Responsive design
    @media (max-width: 768px) {
      padding: 1rem;
      font-size: 1.2rem;
    }
  }
  
  // Fade-in animation for footer text
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
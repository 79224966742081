.referrals_modal {
  // Base styles for the modal with a glass effect
  max-width: 80%;
  padding: 20px;
  background: linear-gradient(
    92.97deg,
    rgba(0, 0, 0, 0.1) 0.05%,
    rgba(49, 213, 57, 0.3) 100.05%
  ); // Glass effect
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 124, 21, 0.2);
  backdrop-filter: blur(10px); // Frosted glass effect
  border: 1px solid rgba(255, 255, 255, 0.2); // Light border for glass effect
  color: #f5f5f5; // Text color
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); // Center the modal
  overflow: hidden;
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 1;

  &.hidden {
    opacity: 0;
    transform: translate(-50%, -60%); // Adjust position when hidden
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2); // Subtle border
    padding-bottom: 10px;
  }

  .earnings-section {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding-bottom: 15px;
    margin-bottom: 20px;

    .earnings-details {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .usdt {
        font-size: 14px;
        font-weight: 400;
        color: #e0e0e0; // Light text for better contrast
      }
    }
  }

  .referrals_dropdown {
    max-height: 200px;
    overflow-y: auto;
    background: rgba(37, 2, 237, 0.2); // Slight background to enhance glass effect
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 20px;
  }

  // .level-cards {
  //   display: flex;
  //   gap: 15px;
  //   // grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  //   margin-top: 20px;
  //   justify-content: center;
  //   // width: 550px;
  // }

  .footer {
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
    color: #e0e0e0; // Light text for better contrast
  }
}

// Styles for the dropdown toggle button
.dropdown-toggle {
  cursor: pointer;
  background: rgba(67, 1, 198, 0.3);
  border: none;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  transition: background 0.3s ease;

  &:hover {
    background: rgba(0, 0, 0, 0.5);
  }
}

// Styles for the referrals list
.referrals_list {
  // Add styles for the referrals list if needed
}

// Styles for each referral item
.referral-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

// Styles for person activity and activity items
.person-activity {
  // Add styles for the activity details if needed
}

.activity-item {
  // Add styles for individual activity items if needed
}

// Level cards styles
.level-cards {
  // Add styles for level cards if needed
}

.level-card {
  // display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1); // Glass effect
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  max-width: 350px;
  box-shadow: 0 4px 8px rgba(34, 243, 2, 0.3);
  backdrop-filter: blur(10px); // Glass effect
  border: 1px solid rgba(255, 255, 255, 0.696);
  position: relative;
  overflow: hidden; // Ensure content doesn't overflow
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;

  h3 {
    margin: 15px 0;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
  }

  p {
    margin: 5px 0;
    font-size: 14px;
    color: #ddd;
  }

  .level-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 20px;
    overflow-y: auto;
    transition: opacity 0.3s ease;
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    text-align: left;

    h4 {
      margin-top: 0;
    }

    ul {
      list-style-type: disc;
      padding-left: 20px;

      li {
        margin: 5px 0;
      }
    }

    .close-button {
      background: rgba(76, 4, 176, 0.2);
      border: none;
      color: #fff;
      padding: 10px;
      border-radius: 5px;
      cursor: pointer;
      position: absolute;
      top: 10px;
      right: 10px;
      transition: background 0.3s ease;

      &:hover {
        background: rgba(255, 255, 255, 0.3);
      }
    }
  }

  &.locked {
    background: rgba(0, 255, 60, 0.3); // Locked card color
    color: #fff;

    .level-details {
      background: rgba(0, 0, 0, 0.9); // Darker background for details when locked
    }
  }

  &.unlocked {
    background: #26ec0792; // Unlocked card color
    color: #ffffff;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(4, 225, 26, 0.4);
  }

  @media (max-width: 768px) {
    max-width: 90%;
    padding: 15px;
  }
}
.level-cards-container {
  overflow-x: auto;
  white-space: nowrap;
  padding: 15px 0;
  margin-bottom: 20px;

  /* Hide scrollbar for Webkit-based browsers */
  &::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.level-cards {
  display: inline-flex;
  gap: 15px; /* Space between cards */
}

.level-card {
  flex:  auto;
  /* Add styles for your cards here, e.g., width, padding, etc. */
}
/* Modals.scss */



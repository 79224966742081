.luxury-box {
    position: relative;
    background: linear-gradient(135deg, #1e1e1e, #3a3a3a);
    border: 2px solid rgba(0, 255, 0, 0.8);
    border-radius: 20px;
    box-shadow: 0px 0px 20px rgba(0, 255, 13, 0.5);
    padding: 40px;
    max-width: 400px;
    text-align: center;
    color: #fff;
    font-family: "Poppins", sans-serif;
    transition: 0.3s;
    cursor: pointer;
  
    &:hover {
      transform: scale(1.05);
      box-shadow: 0px 0px 30px rgb(0, 255, 17);
    }
  
    .countdown-timer {
      position: absolute;
      top: -15px;
      left: 50%;
      transform: translateX(-50%);
      background: linear-gradient(90deg, #15ff00, #2bff00);
      padding: 8px 15px;
      border-radius: 8px;
      font-size: 14px;
      font-weight: bold;
      color: #000;
      box-shadow: 0px 0px 10px rgba(21, 255, 0, 0.8);
    }
  
    .box-title {
      font-size: 24px;
      font-weight: bold;
      color: #00ff1e;
      margin-bottom: 10px;
    }
  
    .box-message {
      font-size: 18px;
      font-weight: 600;
      background: linear-gradient(90deg, #00ff1a, #11ff00);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  
    .box-subtext {
      font-size: 14px;
      color: #ccc;
      margin-top: 5px;
    }
  
    .activate-btn {
        background: linear-gradient(135deg, #00ff1a, #11ff00);
        color: #000;
      padding: 12px 20px;
      font-size: 16px;
      font-weight: bold;
      border: none;
      border-radius: 10px;
      cursor: pointer;
      margin-top: 15px;
      transition: 0.3s;
  
      &:hover {
        background: linear-gradient(135deg, #00ff1a, #11ff00);
        transform: scale(1.1);
      }
    }
  }
  
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .popup-box {
    background: linear-gradient(135deg, #222, #444);
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0px 0px 20px rgba(0, 255, 17, 0.5);
    max-width: 400px;
    text-align: center;
    color: #fff;
    font-family: "Poppins", sans-serif;
  }
  
  .popup-title {
    font-size: 22px;
    font-weight: bold;
    color: #15ff00;
    margin-bottom: 10px;
  }
  
  .popup-message {
    font-size: 16px;
    font-weight: 600;
  }
  
  .popup-requirements {
    list-style: none;
    padding: 0;
    text-align: left;
    margin-top: 10px;
  
    li {
      margin: 5px 0;
      font-size: 14px;
      color: #ddd;
    }
  }
  
  .timer {
    font-weight: bold;
    color: #ff0000;
  }
  
  .popup-btn {
    background: linear-gradient(135deg, #00ff1a, #11ff00);
    color: #000;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 15px;
    transition: 0.3s;
  
    &:hover {
        background: linear-gradient(135deg, #00ff1a, #11ff00);
      transform: scale(1.1);
    }
  }
  
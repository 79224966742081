%base-styles {
  outline: none;
  border: none;
  // padding: 2px 1.5em;
  font-weight: 500;
  font-size: 1rem;
  line-height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &:hover:not(:disabled) {
    transform: translateY(-2px);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  }

  &:active {
    transform: translateY(0px);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  }
}

.btn-primary {
  @extend %base-styles;
  background: linear-gradient(
    45deg,
    #470895 0%,
    #1e2328 50%,
    #ad0a77 100%
  );
  color: var(--text-primary);
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.15);
  animation: fadeIn 0.6s ease-in-out;

  &:hover:not(:disabled) {
    background: linear-gradient(
      45deg,
      #470895 0%,
    #1e2328 50%,
    #ad0a77 100%
    );
  }
}

.btn-secondary {
  @extend %base-styles;
  background: transparent;
  color: #000;
  border: 1px solid #fff;
  animation: fadeIn 0.6s ease-in-out;

  &:hover:not(:disabled) {
    background: rgba(255, 255, 255, 0.1);
  }
}

.btn-error {
  @extend %base-styles;
  background-color: rgb(74, 255, 71);
  color: var(--text-primary);
  box-shadow: 0px 4px 10px rgba(71, 255, 102, 0.25);
  padding: 0.8rem 1.2rem;
  font-size: 1.4rem;
  animation: fadeIn 0.6s ease-in-out;

  &:hover:not(:disabled) {
    background-color: rgb(25, 0, 139);
  }
}

.btn-primary-gradient {
  @extend %base-styles;
  background: var(--btn-primary-gradient);
  color: var(--text-primary);
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.15);
  animation: fadeIn 0.6s ease-in-out;

  &:hover:not(:disabled) {
    background-position: right center;
    background-size: 200% auto;
  }
}

// Fade In Animation
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
